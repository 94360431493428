import React from "react";
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container,
    Spinner, Form
} from "react-bootstrap";
import CustomSearchBox from "components/Shared/CustomSearchBox";
import {formatCurrency} from 'utils/FormatCurrency.js';

import {PURCHASEORDER_EDIT} from 'constants/Permissions.js';
import {getPermission} from 'utils/Permissions.js';
import {useSelector} from 'react-redux'

function PurchaseOrderLineTable(props) {
    const user = useSelector((state) => state.user);

    const SearchStyle = {
        marginTop: "-35px",
        marginLeft: "245px",
    };
    const btnStyle = {
        marginTop: "-50px",
        marginLeft: "61%",
    }
    const renderTableData = () => {
        if (props.PurchaseOrderLineData.length > 0) {
            return props.PurchaseOrderLineData.map((item, index) => {
                const {
                    productId,
                    productName,
                    quantity,
                    allocated,
                    costPrice,
                    tax,
                    discountPercentage,
                    discount,
                    totalPrice,
                    supplierBarcode,
                    brandCode,
                    colour,
                    size,
                    id,
                    allocations,
                    sku
                } = item
                return (
                    <tr key={index}>
                        <td onClick={() => props.handleGetItemCard(productId)}>
                            <a href="#id">{productName}<br/>{sku}</a>
                        </td>
                        <td>{quantity + " (" + allocated + ")"}</td>
                        <td>{formatCurrency(costPrice)}</td>
                        <td>{discountPercentage}%</td>
                        <td>{formatCurrency(totalPrice)}</td>
                        <td>{supplierBarcode}</td>
                        <td>{brandCode}</td>
                        <td>{size}</td>
                        <td>{colour}</td>

                        {allocations.map((allocation, index) => {
                            return <td key={index}>{allocation.quantity} ({allocation.allocated})</td>
                        })}
                        {getPermission(user.permissions, PURCHASEORDER_EDIT) &&
                            <>
                                <td>
                                    <OverlayTrigger
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        overlay={
                                            <Tooltip id="tooltip-981231696">
                                                Edit
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            onClick={() => props.handleEditPurchaseOrderLine(id)}
                                            className="btn-link btn-xs"
                                            variant="success"
                                        >
                                            <i className="fas fa-edit"></i>
                                        </Button>
                                    </OverlayTrigger>
                                </td>
                                <td>
                                    <OverlayTrigger
                                        href="#pablo"
                                        onClick={(e) => e.preventDefault()}
                                        overlay={
                                            <Tooltip id="tooltip-981231696">
                                                Remove
                                            </Tooltip>
                                        }
                                    >
                                        <Button
                                            onClick={() => props.handleDeletePurchaseOrderLine(id)}
                                            className="btn-link btn-xs"
                                            variant="success"
                                        >
                                            <i className="fas fa-times"></i>
                                        </Button>
                                    </OverlayTrigger>
                                </td>
                            </>
                        }
                        <td>
                            <OverlayTrigger
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip id="tooltip-981231696">
                                        {allocated === quantity ? "Allocated" : allocated > 0 ? "Part allocated" : "Unallocated"}
                                    </Tooltip>
                                }
                            >

                                <div
                                    className={`traffic-light ${allocated === quantity ? "green" : allocated > 0 ? "yellow" : "red"}`}/>

                            </OverlayTrigger>
                        </td>
                    </tr>
                )
            })

        }
    }
    return (
        <Card className="strpied-tabled-with-hover">
            <Card.Header>
                <Card.Title as="h4">Purchase Order Lines</Card.Title>
                <Row>
                    <Col md="3" style={SearchStyle}>
                        <CustomSearchBox
                            placeholder="Search"
                            //    value={props.search}
                            handleChange={(e) => props.handleSearchChange(e)}
                            handleClear={() => props.handleSearchChange("")}
                        />
                    </Col>
                    {getPermission(user.permissions, PURCHASEORDER_EDIT) &&
                        <Col className="d-flex justify-content-end pt-3">
                            <OverlayTrigger
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip id="tooltip-255158527">
                                        Add
                                    </Tooltip>
                                }
                            >
                                <Button style={btnStyle}
                                        className="btn-fill"
                                        variant="primary"
                                        onClick={() => props.handleAddPurchaseOrderLine()}
                                >
                                    Add
                                </Button>
                            </OverlayTrigger>
                        </Col>
                    }
                </Row>
            </Card.Header>
            <Card.Body className="table-responsive p-0">
                {props.isLoading ?
                    <div style={{height: 120}} className="d-flex justify-content-center align-items-center text-center">
                        <div>
                            <Spinner animation="border"/>
                            <p>Loading...</p>
                        </div>
                    </div> :
                    <>
                        {(props.PurchaseOrderLineData && props.PurchaseOrderLineData.length == 0) &&
                            (<p
                                style={{height: 120}}
                                className="d-flex justify-content-center align-items-center text-center"
                            >
                                No data found.
                            </p>)}
                    </>
                }
                {(props.PurchaseOrderLineData.length !== 0) && !props.isLoading &&
                    < Col md="20">
                        <Table className="table-hover table-striped w-full">
                            <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>Cost Price</th>
                                <th>Discount %</th>
                                <th>Total Price</th>
                                <th>Barcode</th>
                                <th>Brand Code</th>
                                <th>Size</th>
                                <th>Colour</th>
                                {props.PurchaseOrderLineData.length > 0 && props.PurchaseOrderLineData[0].allocations.length > 0 && props.PurchaseOrderLineData[0].allocations.map((allocation, index) => {
                                    return <th key={index}>{allocation.locationCode}</th>
                                })}
                                <th></th>
                                <th></th>
                            </tr>
                            </thead>
                            <tbody>
                            {renderTableData()}
                            </tbody>
                        </Table>
                    </Col>
                }
            </Card.Body>
        </Card>
    )
}

export default PurchaseOrderLineTable