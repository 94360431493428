import React, { useState, useEffect } from "react";
//import Moment from 'react-moment';
// react component used to create charts
import { showSweetAlert } from "components/Shared/CustomAlert";
// react-bootstrap components
import { Container, Row, Col } from "react-bootstrap";
import { useSelector, } from "react-redux";
import CustomModal from "../../components/Shared/CustomModal";

import { ADMIN_USERMANAGEMENT_VIEW } from "constants/Permissions.js";
import { getPermission } from "utils/Permissions.js";
import RoleTable from "../../components/RoleManagement/RoleTable";
import RolePage from "../../components/RoleManagement/RolePage";

function RoleManagement() {
    const user = useSelector((state) => state.user);
    // ### STATE ###
    const [error, setError] = useState({
        name: ""
    });

    const [roles, setRoles] = useState([]);
    const [isEdit, setIsEdit] = useState(false);
    const [roleId, setRoleId] = useState(0);
    const [selectedRole, setSelectedRole] = useState({})
    const [selectedRolePermissions, setSelectedRolePermissions] = useState({})
    const [showAddEditModal, setShowAddEditModal] = useState(false)
    const [permissions, setPermissions] = useState([])

    //hooks
    const handleCloseAddEditModal = () => setShowAddEditModal(false);

    // ### HANDLERS ###

    const clearAddUserValidation = () => {
        setError({
            name: ""
        });
    };

    const handleAdd = () => {
        clearAddUserValidation();
        setRoleId(0);
        setSelectedRole({
            id: 0,
            name: ""
        });
        setSelectedRolePermissions([])
        setShowAddEditModal(true);
        setIsEdit(true);
    };
    const handleShowRole = async (id) => {
        try {
            let url = `/role/getsingle?roleId=${id}&userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const role = await response.json();

            setRoleId(id);
            setSelectedRole(role)
            setSelectedRolePermissions(role.permissions);
            setShowAddEditModal(true);
            setIsEdit(false);
        } catch (err) { }
    }

    const handleEdit = async () => {
        try {
            setIsEdit(true);
        } catch (err) { }
    };

    const handleShowSave = (newPermissions) => {
        showSweetAlert("warning", `Are you sure to save the role?`, () => {
            hideSave(newPermissions);
        });
    };

    const hideSave = (newPermissions) => {
        handleSave(newPermissions);
    };

    // Apply validation in Input Feilds
    const validateInput = (name, value) => {
        setError((prev) => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "name":
                    if (!value) {
                        stateObj[name] = "Please enter a role name";
                    }
                    break;
                default:
                    break;
            }

            return stateObj;
        });
    };

    const isEmpty = (value) => {
        return value === null || value === undefined || value.trim() === "";
    };

    //Handle save for Add and Edit Users
    const handleSave = async (newPermissions) => {
        const newErrors = {};

        if (isEmpty(selectedRole.name)) {
            newErrors.name = "Please enter a role name";
        }


        // set the errors and return if any before checking for duplicate usernames etc
        setError(newErrors);
        // if any errors then return and dont save
        if (Object.keys(newErrors).length > 0) {
            return;
        }

        const copySelected = { ...selectedRole }
        if (newPermissions && newPermissions.length > 0) {
            copySelected.permissions = newPermissions
        }

        if (roleId !== 0) {
            try {
                let url = `/role/update?userId=${localStorage.getItem(
                    "userId"
                )}&userToken=${localStorage.getItem("userToken")}`;
                const headers = {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(copySelected),
                };
                const response = await fetch(url, headers);

                if (!response.ok) {
                    console.error("Error updating role")
                }

                fetchRoles();
                setRoleId(0);
                setSelectedRole({})
                setSelectedRolePermissions([]);
                setShowAddEditModal(false);
                setIsEdit(false);
            } catch (err) { }
        } else {

            try {
                let url = `/role/add?userId=${localStorage.getItem(
                    "userId"
                )}&userToken=${localStorage.getItem("userToken")}`;
                const headers = {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(copySelected),
                };
                const response = await fetch(url, headers);

                if (!response.ok) {
                    console.error("Error updating role")
                }

                fetchRoles();
                setRoleId(0);
                setSelectedRole({})
                setSelectedRolePermissions([]);
                setShowAddEditModal(false);
                setIsEdit(false);
            } catch (err) { }

        }

    };

    const handleChange = (field, value) => {
        const copySelected = { ...selectedRole }
        copySelected[field] = value

        setSelectedRole(copySelected)
    }


    const handleDelete = (id) => {
        showSweetAlert("deleteWarning", `role`, () => {
            successDelete(id);
        });
    };
    const successDelete = async (id) => {
        try {
            const response = await fetch(
                `/role/delete?roleId=${id}&userId=${localStorage.getItem(
                    "userId"
                )}&userToken=${localStorage.getItem("userToken")}`,
                { method: "DELETE" }
            );
            showSweetAlert("deleteSuccess", `Role`);
            await fetchRoles();
        } catch (err) { }
    };

    // ### DATA FETCHES ###

    const fetchRoles = async () => {
        try {
            let url = `/role/getall?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const body = await response.json();

            setRoles(body);
        } catch (err) { }
    };

    const fetchAllPermissions = async () => {
        try {
            let url = `/permission/getall?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const permissions = await response.json();

            setPermissions(permissions)

        } catch (err) { }
    }


    // ### HOOKS ###

    useEffect(() => {
        fetchRoles();
        fetchAllPermissions()
    }, []);

    return (
        <>
            {alert}
            <CustomModal
                show={showAddEditModal}
                handleClose={handleCloseAddEditModal}
                title={roleId === 0 ? "Add Role" : "Edit Role"}
                backdrop="static"
            >
                <RolePage
                    roleData={selectedRole}
                    permissions={selectedRolePermissions}
                    roles={roles}
                    basePermissions={permissions}
                    handleEdit={handleEdit}
                    isEdit={isEdit}
                    handleShowSave={handleShowSave}
                    validateInput={validateInput}
                    handleChange={handleChange}
                    error={error}
                />
            </CustomModal>

            {getPermission(user.permissions, ADMIN_USERMANAGEMENT_VIEW) && (
                <Container fluid>
                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <Container style={{ height: 750 }} fluid>
                                    <Row className="elysium-h100">
                                        <RoleTable
                                            rolesData={roles}
                                            handleAdd={handleAdd}
                                            handleShow={handleShowRole}
                                            handleDelete={handleDelete}
                                        />
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            )}
            {!getPermission(user.permissions, ADMIN_USERMANAGEMENT_VIEW) && (
                <Container className="mt-4 ms-4">
                    You are not authorized to view this page
                </Container>
            )}
        </>
    );
}

export default RoleManagement;
