import InventoryModal from "../ItemCard/InventoryModal"
import ItemCard from "../ItemCard/ItemCard"
import WebOrdersModal from "../ItemCard/WebOrdersModal"
import React, { useState, useEffect } from "react"
import CustomModal from "./CustomModal"


const ModalContainer = (props) => {
    console.log("c", props)
    const [selectedProductId, setSelectedProductId] = useState(0);
    const [selectedInventory, setSelectedInventory] = useState([]);
    const [showItemCardModal, setShowItemCardModal] = useState(false);
    const [showInventoryModal, setShowInventoryViewModal] = useState(false);
    const [isNavBack, setIsNavBack] = useState(null);

    const [showWebOrdersModal, setShowWebOrdersModal] = useState(false);
    const [itemCardWebOrderInfoType, setItemCardWebOrderInfoType] = useState();
    const [itemCardWebOrderInfo, setItemCardWebOrderInfo] = useState([]);

    const handleGetItemCard = () => {
        setShowItemCardModal(true);
    };


    // Inventory
    const handleShowInventoryModal = (item) => {
        setSelectedInventory(item);
        setShowInventoryViewModal(true);
        setShowItemCardModal(false);
    };

    const handleHideInventoryModal = () => {
        setShowInventoryViewModal(false);
        setShowItemCardModal(true);
    };

    // Web Orders
    const handleShowWebOrdersModal = (item) => {
        setSelectedInventory(item);
        setIsNavBack(false);
        setShowItemCardModal(false);
        setShowWebOrdersModal(true);
    };

    const handleHideWebOrdersModal = () => {
        setShowWebOrdersModal(false);
        setShowItemCardModal(true);
        setIsNavBack(true);
        setItemCardWebOrderInfo([]);
        setItemCardWebOrderInfoType("");
    };

    const handleGetItemCardCommittedList = async (id, location) => {
        if (!id) return;
        try {
            const url = `/ItemCard/GetCommittedList?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem(
                "userToken"
            )}&productId=${id}&locationId=${location}`;
            const response = await fetch(url);
            const body = await response.json();
            return body;
        } catch (err) {
            showSweetAlert("serverError");
        }
    };

    const handleGetItemCardInTransitList = async (id, location) => {
        if (!id) return;
        try {
            const url = `/ItemCard/GetInTransitList?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem(
                "userToken"
            )}&productId=${id}&locationId=${location}`;
            const response = await fetch(url);
            const body = await response.json();
            return body;
        } catch (err) {
            showSweetAlert("serverError");
        }
    };

    const handleGetItemCardSoldList = async (id, location) => {
        if (!id) return;
        try {
            const url = `/ItemCard/GetSoldList?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem(
                "userToken"
            )}&productId=${id}&locationId=${location}`;

            const response = await fetch(url);
            const body = await response.json();
            return body;
        } catch (err) {
            showSweetAlert("serverError");
        }
    };

    const handleGetItemCardWebOrderInfo = async (type, productId, locationId) => {
        switch (type) {
            case "onHand":
                // do nothing
                break;
            case "Committed":
                setItemCardWebOrderInfo(
                    await handleGetItemCardCommittedList(productId, locationId)
                );
                setItemCardWebOrderInfoType("Committed");
                break;
            case "InTransit":
                setItemCardWebOrderInfo(
                    await handleGetItemCardInTransitList(productId, locationId)
                );
                setItemCardWebOrderInfoType("In Transit");
                break;
            case "Sold":
                setItemCardWebOrderInfo(
                    await handleGetItemCardSoldList(productId, locationId)
                );
                setItemCardWebOrderInfoType("Sold");
                break;
            default:
                break;
        }
    };

    useEffect(() => {
        if (props.selectedProductId?.id && props.selectedProductId.id !== 0) {
            setSelectedProductId(props.selectedProductId.id)

            if (props.selectedProductId.first === "itemCard") {
                handleGetItemCard()
            } else if (props.selectedProductId.first === "webOrder") {
                handleGetItemCardWebOrderInfo(props.selectedProductId.type, props.selectedProductId.id, props.selectedProductId.locationId)
                setShowWebOrdersModal(true)
            }

        }
    }, [props.selectedProductId])

    return (
        <>
            <CustomModal
                show={showItemCardModal}
                handleClose={props.handleItemCardCloseModal}
                title=""
                backdrop="static"
            >
                <ItemCard
                    id={selectedProductId}
                    handleShowInventoryModal={handleShowInventoryModal}
                    selectedInventory={selectedInventory}
                    handleShowWebOrdersModal={handleShowWebOrdersModal}
                    handleGetItemCardWebOrderInfo={handleGetItemCardWebOrderInfo}
                    isNavBack={isNavBack}
                />
            </CustomModal>
            <CustomModal
                show={showInventoryModal}
                handleClose={() => {
                    handleHideInventoryModal, setSelectedProductId(selectedProductId);
                }}
                title=""
                backdrop="static"
            >
                <InventoryModal
                    selectedInventory={selectedInventory}
                    handleHideInventoryModal={handleHideInventoryModal}
                />
            </CustomModal>

            <CustomModal
                show={showWebOrdersModal}
                handleClose={props.selectedProductId.first === "itemCard" ? handleHideWebOrdersModal : props.handleHideWebOrdersModal}
                title=""
                backdrop="static"
            >
                <WebOrdersModal
                    itemCardWebOrderInfo={itemCardWebOrderInfo}
                    itemCardWebOrderInfoType={itemCardWebOrderInfoType}
                    selectedProductId={selectedProductId}
                    handleSelectMovement={props.handleSelectMovement}
                />
            </CustomModal>
        </>
    )

}

export default ModalContainer