import React, { useState, useEffect, useMemo, useRef } from "react";
import {
    Card,
    Container,
    Row,
    Col,
    Button,
    Form,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";
import DateRange from "../../components/Shared/DateRange";
import CustomSelect from "../../components/Shared/CustomSelect";
import OrdersTable from "components/Orders/OrdersTable";
import OrdersModal from "components/Orders/OrdersModal";
import EditOrderLocationModal from "components/Orders/EditOrderLocationModal";
import { useSelector } from "react-redux";
import moment from "moment";
import CustomModal from "../../components/Shared/CustomModal";
import { showSweetAlert } from "components/Shared/CustomAlert";
import InventoryModal from "../../components/ItemCard/InventoryModal";
import ItemCard from "../../components/ItemCard/ItemCard";
import { useLocation } from "react-router-dom";
// to change to orders view
import { MOVEMENTS_VIEW, ORDERS_VIEW } from "constants/Permissions.js";
import { getPermission } from "utils/Permissions.js";
import Swal from "sweetalert2";
import ModalContainer from "../../components/Shared/ModalContainer";
// import router from "http-proxy-middleware/lib/router";

function Orders() {
    // ### STATE ###
    const orderSearch = useSelector((state) => state.Search);
    const user = useSelector((state) => state.user);

    const [search, setSearch] = useState("");

    const defaultDate = new Date();
    const [dateRange, setDateRange] = useState([
        defaultDate.setMonth(defaultDate.getMonth() - 3),
        new Date(),
    ]);

    // const [locationFromId, setLocationFromId] = useState(0);
    // const handleLocationFromChange = (id) => {
    //   setLocationFromId(id);
    //   localStorage.setItem("locationFromId", id);
    // };

    const [locationId, setLocationId] = useState(0);
    const handleLocationToChange = (id) => {
        setLocationId(id);
        localStorage.setItem("locationId", id);
    };

    const [locations, setLocations] = useState([]);
    const [locationDropdown, setLocationDropdown] = useState([]);
    const [orderBy, setOrderBy] = useState("DateAmended");
    const [filterDateData, setfilterDateData] = useState([]);
    const [descending, setDescending] = useState(true);
    const [isLoading, setIsLoading] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(1);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(20);
    const [originBatchData, setOriginBatchData] = useState([]);
    const [singleOrder, setSingleOrder] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const [showBatchModal, setShowBatchModal] = useState(false);
    const handleCloseBatchModal = () => setShowBatchModal(false);
    const handleShowBatchModal = () => setShowBatchModal(true);

    const [showItemCardModal, setShowItemCardModal] = useState(false);
    const [showInventoryModal, setShowInventoryViewModal] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(1);
    const [selectedInventory, setSelectedInventory] = useState([]);
    const [selectedProductId, setSelectedProductId] = useState(0);
    const [selectedOrderLine, setSelectedOrderLine] = useState();

    const [showEditOrderLocationModal, setShowEditOrderLocationModal] =
        useState(false);

    const [showOrderModal, setShowOrderModal] = useState(false);
    const handleCloseOrderModal = () => setShowOrderModal(false);
    const handleShowOrderModal = () => setShowOrderModal(true);
    const [orderId, setOrderId] = useState(0);
    const [orderLinesData, setOrderLinesData] = useState([]);
    const [originMovementsProducts, setoriginMovementsProducts] = useState([]);
    const [productData, setProductdata] = useState([]);
    const [productValue, setProductValue] = useState([]);
    const [quantityInStock, setQuantityInStock] = useState(0);
    const [movementChangedValue, setMovementChangedValue] = useState([]);
    const [originData, setOriginData] = useState([]);

    const [hasMore, setHasMore] = useState(true);

    const [error, setError] = useState({
        fromLocationId: "",
        toLocationId: "",
        productId: "",
    });

    // fulfillment Orders
    const [showFulfillmentOrders, setShowFulfillmentOrders] = useState(false);
    const handleToggleShowFulfillmentOrders = (isShowFulfillmentOrders) => {
        setShowFulfillmentOrders(isShowFulfillmentOrders);
    };
    const [fulfillmentOrdersData, setFulfillmentOrdersData] = useState({});

    // edit states - fulfillment orders
    const [
        isChangeFulfillmentOrderLocation,
        setIsChangeFulfillmentOrderLocation,
    ] = useState(false);

    const [checkedLineItems, setCheckedLineItems] = useState([]);

    const handleChangeFulfillmentOrderLocation = (lineItem) => {
        setIsChangeFulfillmentOrderLocation(true);
        let updatedCheckedLineItems = { ...checkedLineItems }; // Create a copy of the state

        // check if the line item already exists
        if (checkedLineItems.find((item) => item.id === lineItem.id)) {
            // remove the line item
            updatedCheckedLineItems = checkedLineItems.filter(
                (item) => item.id !== lineItem.id
            );
            setCheckedLineItems(updatedCheckedLineItems);
        } else {
            // add new item to the array
            setCheckedLineItems([...checkedLineItems, lineItem]);
        }

        // if no items then cancel the change
        if (updatedCheckedLineItems.length === 0) {
            handleCancelChangeFulfillmentOrderLocation();
        }
    };

    // const fetchWithRetry = async (url, options, retries = 3) => {
    //   for (let i = 0; i < retries; i++) {
    //     console.log(`Attempt ${i + 1} to fetch ${url}`);
    //     try {
    //       const response = await fetch(url, options);
    //       if (response.status < 200 || response.status >= 300) {
    //         throw new Error(`Request failed with status ${response.status}`);
    //       }
    //       const data = await response.json();
    //       return data;
    //     } catch (error) {
    //       console.error(`Attempt ${i + 1} failed: ${error.message}`);
    //       if (i === retries - 1) {
    //         throw error;
    //       }
    //     }
    //   }
    // };

    const handleConfirmChangeFulfillmentOrderLocation = async (
        item,
        selectedLocations
    ) => {
        const fulfillmentLines = item.fulfillmentLines;
        const selectedFulfillmentLines = fulfillmentLines.filter((x) =>
            checkedLineItems.some((y) => y.id === x.id)
        );
        const newLocationId = selectedLocations.find(
            (x) => x.itemId === item.id
        )?.locationId;

        // if no items then cancel the change
        if (selectedFulfillmentLines.length < 1) return;

        // All the lines should be from the same order
        const fulfillmentOrderId = selectedFulfillmentLines[0].fulfillmentId;

        const lineIds = selectedFulfillmentLines.map((line) => line.id);

        const url = `/Fulfilment/ChangeFulfilmentLocation?fulfilmentOrderId=${fulfillmentOrderId}&newLocationId=${newLocationId}`;

        const response = await fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(lineIds),
        });

        if (response.status === 200) {
            showSweetAlert("success", "Success", "Order location updated");
            await fetchFulfillmentOrderLines(orderId);
            // setOrderLinesData(body);
        } else {
            showSweetAlert("serverError");
        }

        return;
    };

    const handleConfirmChangeFulfillmentOrderLocationOld = async (
        item,
        selectedLocations
    ) => {
        // loop through the checked line items and create an array of the items that have the
        // grab item.fulfillmentLines and check which ones are in the checked line items - these added to array
        const fulfillmentLines = item.fulfillmentLines;
        const selectedFulfillmentLines = fulfillmentLines.filter((x) =>
            checkedLineItems.some((y) => y.id === x.id)
        );

        if (selectedFulfillmentLines.length < 1) return;

        // now we have the selected lines and also the location. We need to loop through these and make an api call for each
        let allSuccessful = true;
        for (const line of selectedFulfillmentLines) {
            const fulfillmentOrderId = line.fulfillmentId;
            const lineId = line.id;
            const quantity = line.quantity;
            const newLocationId = selectedLocations.find(
                (x) => x.itemId === item.id
            )?.locationId;

            const url = `/Fulfilment/ChangeFulfilmentLocation?fulfilmentOrderId=${fulfillmentOrderId}&lineId=${lineId}&quantity=${quantity}&newLocationId=${newLocationId}`;
            try {
                const response = await fetchWithRetry(url, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
            } catch (error) {
                showSweetAlert("serverError");
                allSuccessful = false;
            }
        }

        if (allSuccessful) {
            showSweetAlert("success");
            fetchFulfillmentOrderLines(singleOrder.id);
        }

        return;
    };

    const handleCancelChangeFulfillmentOrderLocation = (isCancelAll) => {
        if (isCancelAll) {
            setCheckedLineItems([]);
        }
        setIsChangeFulfillmentOrderLocation(false);
    };

    // picked status functions - order lines / fulfillment orders table modal
    const [pickedStatusDropdownOptions, setPickedStatusDropdownOptions] =
        useState([
            { id: 4, value: "None selected" },
            { id: 1, value: "Picked" },
            { id: 2, value: "Unavailable" },
            { id: 3, value: "Damaged" },
        ]);

    const handlePickedStatusChange = (pickedStatus, lineItem, order) => {
        // get the user id making the change
        const userId = localStorage.getItem("userId");

        switch (pickedStatus) {
            case 1:
                handlePicked(lineItem, userId, order.id);
                break;
            case 2:
                showSweetAlert(
                    "warning",
                    `Are you sure you want to select unavailable?`,
                    () => {
                        sendEmailOnPickedStatusChange(
                            pickedStatus,
                            lineItem,
                            userId,
                            order.id
                        );
                    }
                );
                break;
            case 3:
                showSweetAlert(
                    "warning",
                    `Are you sure you want to select damaged?`,
                    () => {
                        sendEmailOnPickedStatusChange(
                            pickedStatus,
                            lineItem,
                            userId,
                            order.id
                        );
                    }
                );
                break;
            case 4:
                // case 4 is none selected - do nothing
                break;
            default:
                break;
        }
    };

    const sendEmailOnPickedStatusChange = (pickedStatus, lineItem, orderId) => {
        // Unavailable
        if (pickedStatus === 2) {
            // send email
            handleConfirmStatusChange(lineItem.id, pickedStatus, orderId);
            return;
        }

        // Damaged
        handleConfirmStatusChange(lineItem.id, pickedStatus, orderId);
        return;
    };

    const handlePicked = (lineItem, userId, orderId) => {
        const { barcode, quantity } = lineItem;

        const returnSwalHtml = () => {
            if (quantity > 1) {
                return `Please confirm barcode is <span style="color: red;">${barcode}</span> and quantity is <span style="color: red">${quantity}</span>`;
            }
            return `Please confirm barcode is ${barcode} and quantity is ${quantity}`;
        };

        Swal.fire({
            title: `${returnSwalHtml()}`,
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Confirm",
            denyButtonColor: "#ACACAC ",
            confirmButtonColor: "#FF680B",
            cancelButtonText: "Cancel",
            sanitize: false,
        }).then((result) => {
            if (result.isConfirmed) {
                handleConfirm();
            }
        });

        const handleConfirm = async () => {
            handleConfirmStatusChange(lineItem.id, 1, orderId);
        };
    };

    // send the email on picked status changed / confirmed change
    const handleConfirmStatusChange = async (
        fulfilmentLineId,
        pickedStatus,
        orderId
    ) => {
        const userId = localStorage.getItem("userId");
        const userToken = localStorage.getItem("userToken");

        const url = `/Fulfilment/SetFulfilmentLineStatus?userId=${userId}&userToken=${userToken}&fulfilmentLineId=${fulfilmentLineId}&status=${pickedStatus}`;

        const response = await fetch(url, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
            },
        });

        if (response.status !== 200) {
            showSweetAlert("serverError");
            return;
        }

        await fetchFulfillmentOrderLines(orderId);

        showSweetAlert("success", "Success", "Status changed.");
    };

    const [fulfillmentOrderLines, setFulfillmentOrderLines] = useState();
    const fetchFulfillmentOrderLines = async (orderId) => {
        // fetch
        try {
            let url = `/Fulfilment/GetAll?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}&orderId=${orderId}`;

            const response = await fetch(url);

            const body = await response.json();
            setFulfillmentOrderLines(body);
        } catch (error) {
            console.log(error);
        }
    };

    const validateInput = (name, value) => {
        setError((prev) => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "fromLocationId":
                    if (value <= 0) {
                        stateObj[name] = "Please select at least one value";
                    }
                    break;

                case "toLocationId":
                    if (value <= 0) {
                        stateObj[name] = "Please select at least one value";
                    }
                    break;
                case "productId":
                    if (value <= 0) {
                        stateObj[name] = "Please select at least one value";
                    }
                    break;
                default:
                    break;
            }

            return stateObj;
        });
    };
    // ### HANDLERS ###
    const ordersModal = (orderId) => {
        setOrderId(orderId);
        // no longer need to fetch single order - just extract the fulfillment order lines data
        fetchSingleOrder(orderId);
        fetchFulfillmentOrderLines(orderId);
        // fetchMovementProducts(orderId);
        handleShowModal();
    };

    const handleAdd = () => {
        setError([]);
        setMovementChangedValue({
            id: 0,
            batchName: "",
            fromLocationId: 0,
            fromLocationName: "",
            toLocationId: 0,
            toLocationName: "",
            fromNotes: "",
            toNotes: "",
            typeId: 3,
            typeName: "",
            statusId: 0,
            hasCreatedBatch: true,
        });
        handleShowBatchModal();
    };

    const handleDatesChange = (dates) => {
        setDateRange(dates);
        if (dates[0] && dates[1]) {
            const filterData = originData.filter((item) => {
                return moment(item.timestamp).isBetween(
                    moment(dates[0], "YYYY-MM-DDTHH:mm:ssZ").format(
                        "YYYY-MM-DDTHH:mm:ssZ"
                    ),
                    moment(dates[1], "YYYY-MM-DDTHH:mm:ssZ").format(
                        "YYYY-MM-DDTHH:mm:ssZ"
                    ),
                    null,
                    "[]"
                );
            });
            setfilterDateData(filterData);
            localStorage.setItem("dateRange", filterData);
        }
    };

    const handleSearchChange = (value) => {
        const filterData = originMovementsProducts?.filter((item) =>
            item.productName.toLowerCase().includes(value.toLowerCase())
        );
        setSearch(value);
        setOrderLinesData(filterData);
    };
    // ### DATA FETCHES ###
    const fetchLocations = async () => {
        try {
            let url = `/location/getall?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`; //todo - move to store
            const response = await fetch(url);
            const locations = await response.json();

            const data = locations?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setLocations(data);
        } catch (err) { }
    };
    const fetchLocationDropdown = async () => {
        try {
            let url = `/location/GetDropdownList?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const locations = await response.json();

            const products = locations?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setLocationDropdown(products);
        } catch (err) { }
    };

    const pageRef = useRef(page);
    const handlePageChange = (newPage) => {
        pageRef.current = newPage;
        setPage(newPage);
    };

    useMemo(async () => {
        handlePageChange(0);
        try {
            setData([]);
            setIsLoading(true);

            handlePageChange(page + 1);
            let from = new Date(dateRange[0]);

            const userId = localStorage.getItem("userId");
            const userToken = localStorage.getItem("userToken");
            const dateFrom = new Date(dateRange[0]).toISOString();
            const dateTo = new Date(dateRange[1]).toISOString();
            const orderBy = "CreatedAt";
            const descending = true;

            const url = `/Order/GetAllPaged?userId=${userId}&userToken=${userToken}&skip=${pageRef.current * take + skip
                }&take=${take}&locationId=${locationId}&dateFrom=${dateFrom}&dateTo=${dateTo}&orderBy=${orderBy}&descending=${descending}`;

            const response = await fetch(url);
            const body = await response.json();
            setData(body.data);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    }, [locationId, location, dateRange]);

    const updateData = async () => {
        try {
            handlePageChange(page + 1);
            let from = new Date(dateRange[0]);

            const userId = localStorage.getItem("userId");
            const userToken = localStorage.getItem("userToken");
            const dateFrom = new Date(dateRange[0]).toISOString();
            const dateTo = new Date(dateRange[1]).toISOString();
            const orderBy = "CreatedAt";
            const descending = true;

            const url = `/Order/GetAllPaged?userId=${userId}&userToken=${userToken}&skip=${pageRef.current * take + skip
                }&take=${take}&locationId=${locationId}&dateFrom=${dateFrom}&dateTo=${dateTo}&orderBy=${orderBy}&descending=${descending}`;
            const response = await fetch(url);
            const body = await response.json();
            setTimeout(() => {
                setData(data.concat(body));
                if (body.data && body.data.length == 0) {
                    setHasMore(false);
                }
            }, 500);
        } catch (err) {
            console.log("error", err);
        }
    };

    const fetchGetallpaged = async () => {
        setPage(0);
        setOriginBatchData([]);
        setData([]);
        setIsLoading(true);

        const locationFromId = localStorage.getItem("locationFromId");
        const locationId = localStorage.getItem("locationId");
        const typeId = localStorage.getItem("typeId");
        const statusId = localStorage.getItem("statusId");

        try {
            let from = new Date(dateRange[0]);

            const userId = localStorage.getItem("userId");
            const userToken = localStorage.getItem("userToken");
            const dateFrom = new Date(dateRange[0]).toISOString();
            const dateTo = new Date(dateRange[1]).toISOString();
            const orderBy = "CreatedAt";
            const descending = true;

            const url = `/Order/GetAllPaged?userId=${userId}&userToken=${userToken}&skip=${skip}&take=${take}&locationId=${locationId}&dateFrom=${dateFrom}&dateTo=${dateTo}&orderBy=${orderBy}&descending=${descending}`;

            const response = await fetch(url);

            const body = await response.json();
            setIsLoading(false);
            setOriginBatchData(body);
            setData(body);
            setOriginData(body);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const fetchData = async () => {
        //setIsLoading(true);
        try {
            const userId = localStorage.getItem("userId");
            const userToken = localStorage.getItem("userToken");
            const locationId = localStorage.getItem("locationId");
            const dateFrom = new Date(dateRange[0]).toISOString();
            const dateTo = new Date(dateRange[1]).toISOString();
            const orderBy = "CreatedAt";
            const descending = true;

            const url = `/Order/GetAllPaged?userId=${userId}&userToken=${userToken}&skip=${skip}&take=${take}&locationId=${locationId}&dateFrom=${dateFrom}&dateTo=${dateTo}&orderBy=${orderBy}&descending=${descending}`;

            const response = await fetch(url);

            const body = await response.json();
            setIsLoading(false);
            setOriginBatchData(body);
            setData(body);
        } catch (err) {
            setIsLoading(false);
        }
    };

    const fetchSingleOrder = async (id) => {
        try {
            let url = `/Order/GetSingle?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
            const response = await fetch(url);
            const body = await response.json();
            setSingleOrder(body);
        } catch (err) { }
    };

    const handleChangeProduct = (name, value) => {
        let updatedProductValue = {
            ...productValue,
            [name]: value,
        };
        setProductValue(updatedProductValue);

        const productWithId = productData.find((product) => product.id == value);
        if (productWithId) {
            setQuantityInStock(productWithId.qty);
        }

        validateInput(name, value);
    };

    const handleChangeLocation = (name, value) => {
        let item = {
            ...movementChangedValue,
            [name]: value,
        };
        setMovementChangedValue(item);
        validateInput(name, value);
    };

    const handleGetItemCard = (id) => {
        //setSelectedOrderId(id);
        setSelectedProductId({ id: id, first: "itemCard" });
        handleCloseModal()
    };

    const handleItemCardCloseModal = () => {
        setSelectedProductId(0);
        setSelectedOrderId(0);
        handleShowModal()
    };

    const handleShowInventoryModal = (item) => {
        setSelectedInventory(item);
        setShowInventoryViewModal(true);
        setShowItemCardModal(false);
    };

    const handleHideInventoryModal = () => {
        setShowInventoryViewModal(false);
        setShowItemCardModal(true);
    };

    // ### HOOKS ###

    useEffect(() => {
        fetchLocationDropdown();
        fetchLocations();

        localStorage.setItem("locationFromId", 0);
        localStorage.setItem("locationId", 0);
        localStorage.setItem("typeId", 0);
        localStorage.setItem("statusId", 0);
        localStorage.setItem("dateRange", 0);

        fetchData();
    }, []);

    useEffect(() => {
        fetchGetallpaged();
    }, [locationId, orderSearch, dateRange]);

    let routerLocation = useLocation();
    useEffect(() => {
        if (routerLocation.state) {
            showOrdersModalWebOrder(routerLocation.state.order.id);
            routerLocation.state = null;
        }
    }, []);
    const showOrdersModalWebOrder = async (orderId) => {
        await fetchSingleOrder(orderId);
        setShowModal(true);
    };

    const handleShowEditOrderLocationModal = (orderLine) => {
        setSelectedOrderLine(orderLine);
        setShowEditOrderLocationModal(true);
        setShowModal(false);
    };

    const handleHideEditOrderLocationModal = () => {
        setSelectedOrderLine(null);
        setShowEditOrderLocationModal(false);
        setShowModal(true);
    };

    const handleUpdateOrderLocation = async () => {
        try {
            const url = `/Order/UpdateOrderLine?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`;

            let body = selectedOrderLine;

            const response = await fetch(url, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(body),
            });

            const data = await response.json();
            if (data.error) {
                showSweetAlert("serverError");
                return;
            }

            showSweetAlert("success", "Success", "Order location updated");
            handleHideEditOrderLocationModal();
        } catch (err) { }
    };

    return (
        <>
            <CustomModal
                show={selectedProductId !== 0}
                handleClose={handleItemCardCloseModal}
                title=""
                backdrop="static"
            >
                <ModalContainer
                    selectedProductId={selectedProductId}
                    handleItemCardCloseModal={handleItemCardCloseModal}
                />
            </CustomModal>

            <CustomModal
                show={showEditOrderLocationModal}
                handleClose={handleHideEditOrderLocationModal}
                title=""
                backdrop="static"
            >
                <EditOrderLocationModal
                    singleOrder={singleOrder}
                    isLoading={isLoading}
                    locationDropdown={locationDropdown}
                    handleUpdateOrderLocation={handleUpdateOrderLocation}
                    selectedOrderLine={selectedOrderLine}
                    setSelectedOrderLine={setSelectedOrderLine}
                    handleHideEditOrderLocationModal={handleHideEditOrderLocationModal}
                />
            </CustomModal>

            {showOrderModal == false && (
                <CustomModal show={showModal} handleClose={handleCloseModal} title="">
                    <OrdersModal
                        SingleOrder={singleOrder}
                        isLoading={isLoading}
                        orderLinesData={orderLinesData}
                        search={search}
                        handleSearchChange={handleSearchChange}
                        handleGetItemCard={handleGetItemCard}
                        locationDropdown={locationDropdown}
                        handleShowEditOrderLocationModal={handleShowEditOrderLocationModal}
                        showFulfillmentOrders={showFulfillmentOrders}
                        handleToggleShowFulfillmentOrders={
                            handleToggleShowFulfillmentOrders
                        }
                        locations={locationDropdown}
                        fulfillmentOrdersData={fulfillmentOrdersData}
                        setFulfillmentOrdersData={setFulfillmentOrdersData}
                        handleChangeFulfillmentOrderLocation={
                            handleChangeFulfillmentOrderLocation
                        }
                        isChangeFulfillmentOrderLocation={isChangeFulfillmentOrderLocation}
                        handleCancelChangeFulfillmentOrderLocation={
                            handleCancelChangeFulfillmentOrderLocation
                        }
                        checkedLineItems={checkedLineItems}
                        pickedStatusDropdownOptions={pickedStatusDropdownOptions}
                        handlePickedStatusChange={handlePickedStatusChange}
                        fulfillmentOrderLines={fulfillmentOrderLines}
                        handleConfirmChangeFulfillmentOrderLocation={
                            handleConfirmChangeFulfillmentOrderLocation
                        }
                    />
                </CustomModal>
            )}
            {/* todo - change to order view when added to api perms */}
            {getPermission(user.permissions, MOVEMENTS_VIEW) && (
                <Container fluid>
                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col md="">
                                                <DateRange
                                                    title="Date"
                                                    startDate={dateRange[0]}
                                                    endDate={dateRange[1]}
                                                    range={true}
                                                    handleChange={handleDatesChange}
                                                />
                                            </Col>
                                            <Col md="">
                                                <CustomSelect
                                                    title="Location"
                                                    placeholder="Location"
                                                    id={locationId}
                                                    data={locations}
                                                    handleChange={(e) => handleLocationToChange(e.value)}
                                                    withAll={true}
                                                />
                                            </Col>
                                            {/* <Col md="">
                        <CustomSelect
                          title="Location From"
                          placeholder="Location From"
                          id={locationFromId}
                          data={locations}
                          handleChange={(e) =>
                            handleLocationFromChange(e.value)
                          }
                          withAll={true}
                        />
                      </Col>
                      <Col md="">
                        <CustomSelect
                          title="Location To"
                          placeholder="Location To"
                          id={locationId}
                          data={locations}
                          handleChange={(e) => handleLocationToChange(e.value)}
                          withAll={true}
                        />
                      </Col> */}
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <OrdersTable
                                    isLoading={isLoading}
                                    data={data}
                                    ordersModal={ordersModal}
                                    handleAdd={handleAdd}
                                    locationDropdown={locationDropdown}
                                    updateData={updateData}
                                    take={take}
                                    hasMore={hasMore}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            )}
            {!getPermission(user.permissions, MOVEMENTS_VIEW) && (
                <Container className="mt-4 ms-4">
                    You are not authorized to view this page
                </Container>
            )}
        </>
    );
}
export default Orders;
