import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { showSweetAlert } from "components/Shared/CustomAlert";
import CustomSearchBox from "components/Shared/CustomSearchBox";
import { useDispatch } from "react-redux";
import { formatDate } from "utils/FormatDate.js";

// react-bootstrap components
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container,
    Spinner,
} from "react-bootstrap";

import InfiniteScroll from "react-infinite-scroll-component";

import { MOVEMENTS_EDIT } from "constants/Permissions.js";
import { getPermission } from "utils/Permissions.js";
import { ADMIN_USERMANAGEMENT_VIEW } from "constants/Permissions";

function MovementsTable(props) {
    const SearchStyle = {
        marginTop: "-35px",
        marginLeft: "190px",
    };

    // ### STATE ###
    const user = useSelector((state) => state.user);

    const dispatch = useDispatch();

    const handlePermission = () => {
        showSweetAlert("unauthorized");
    };
    // ### renderTableData ###
    const renderTableData = () => {
        return props.data.map((log, index) => {
            const {
                batchName,
                fromLocationName,
                toLocationName,
                units,
                unitsBookedIn,
                fromNotes,
                toNotes,
                id,
                dateCreated,
                typeName,
                statusId,
                statusName,
                archived,
            } = log;

            return (
                <tr key={`Movements${index}`}>
                    <td onClick={() => props.movementsModel(id)}>
                        <a href="#id">{batchName}</a>
                    </td>
                    <td>{fromLocationName}</td>
                    <td>{toLocationName}</td>
                    <td>{units} ({unitsBookedIn})</td>
                    <td>{typeName}</td>
                    <td>{statusName}</td>
                    <td>{formatDate(dateCreated)}</td>
                    {archived ? (
                        <td className="d-flex justify-content-end align-items-end pe-4">
                            Archived
                        </td>
                    ) : (
                        <td>
                            <OverlayTrigger
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip id="tooltip-255158527">Download Batch Label</Tooltip>
                                }
                            >
                                <Button
                                    className="fa-sharp fa-light fa-arrow-down-to-line"
                                    href="#pablo"
                                    onClick={(e) => props.handlePrintBatchLabel(id, batchName)}
                                    variant="link"
                                >
                                    <i className="fa fa-qrcode"></i>
                                </Button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                                overlay={
                                    <Tooltip id="tooltip-255158527">Download Labels PDF</Tooltip>
                                }
                            >
                                <Button
                                    href="#"
                                    onClick={(e) =>
                                        props.handleBatchProductLabelPDF(id, batchName)
                                    }
                                    variant="link"
                                >
                                    <i className="fa fa-tag"></i>
                                </Button>
                            </OverlayTrigger>
                            {getPermission(user.permissions, MOVEMENTS_EDIT) &&
                                (statusId == 1 || statusId == 2) && (
                                    <Button
                                        href="#"
                                        onClick={(e) => props.handleInTransitMovement(id)}
                                        variant="primary"
                                    >
                                        Mark In Transit
                                    </Button>
                                )}
                            {user.roleId === 1 && statusId == 3 && (
                                <Button
                                    href="#"
                                    onClick={(e) => props.handleReopenMovement(id)}
                                    variant="primary"
                                >
                                    Reopen Movement
                                </Button>
                            )}
                            {getPermission(user.permissions, MOVEMENTS_EDIT) &&
                                <Button
                                    className="float-end"
                                    href="#"
                                    onClick={(e) => props.handleArchiveMovement(id)}
                                    variant="primary"
                                >
                                    Archive
                                </Button>
                            }
                        </td>
                    )}
                </tr>
            );
        });
    };
    return (
        <>
            <Card className="strpied-tabled-with-hover">
                <Card.Header>
                    <Card.Title as="h4">Batches</Card.Title>
                    <p className="card-category"></p>

                    <Col md="3" style={SearchStyle}>
                        <CustomSearchBox
                            placeholder="Search"
                            handleChange={(e) =>
                                dispatch({ type: "SetUsernameValid", Search: e })
                            }
                            handleClear={() =>
                                dispatch({ type: "SetUsernameValid", Search: "" })
                            }
                        />
                    </Col>

                    <OverlayTrigger
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                        overlay={<Tooltip id="tooltip-255158527">Add Movement</Tooltip>}
                    >
                        <Button
                            style={SearchStyle}
                            className="btn-fill float-end"
                            variant="primary"
                            onClick={() => props.handleAdd()}
                        >
                            Add
                        </Button>
                    </OverlayTrigger>
                </Card.Header>
                <Card.Body className="table-responsive p-0">
                    {props.data && props.data.length == 0 && !props.isLoading && (
                        <p
                            style={{ height: 550 }}
                            className="d-flex justify-content-center align-items-center text-center"
                        >
                            No data found.
                        </p>
                    )}

                    {props.data && props.data.length > 0 && !props.isLoading && (
                        <Col md="12">
                            <InfiniteScroll
                                dataLength={props.data.length}
                                next={props.updateData}
                                hasMore={props.hasMore}
                                loader={
                                    props.data.length >= props.take ? (
                                        <h6>Loading more data...</h6>
                                    ) : (
                                        ""
                                    )
                                }
                                height={650}
                            >
                                <Table className="table-hover table-striped w-full">
                                    <thead>
                                        <tr>
                                            <th>Batch Id</th>
                                            <th>From Location Name</th>
                                            <th>To Location Name</th>
                                            <th>Units</th>
                                            <th>Type Name</th>
                                            <th>Status</th>
                                            <th>Date Created</th>
                                        </tr>
                                    </thead>
                                    <tbody>{renderTableData()}</tbody>
                                </Table>
                            </InfiniteScroll>
                        </Col>
                    )}
                </Card.Body>
            </Card>
        </>
    );
}
export default MovementsTable;
