import React, { useState, useEffect } from "react"
import { showSweetAlert } from "../Shared/CustomAlert";
import CustomModal from "../Shared/CustomModal";
import MovementsModal from "./MovementsModal";
import AddEditMovementsModal from "./AddEditMovementsModal";
import AddEditMovementProduct from "./AddEditMovementProduct";
import { useSelector } from "react-redux";

const MovementModalContainer = (props) => {
    const user = useSelector((state) => state.user);
    const [isLoading, setIsLoading] = useState(true);
    const [movementId, setMovementId] = useState(false)
    const [singleMovement, setsingleMovement] = useState([]);
    const [movementChangedValue, setMovementChangedValue] = useState([]);
    const [quantityInStock, setQuantityInStock] = useState(0);
    const [productValue, setProductValue] = useState([]);

    const [originMovementsProducts, setoriginMovementsProducts] = useState([]);
    const [MovementsProductData, setMovementsProductData] = useState([]);

    const [productData, setProductdata] = useState([]);

    const [getLocationDropdown, setLocationDropdown] = useState([]);
    const [selectedFromLocation, setSelectedFromLocation] = useState();
    const [selectedToLocation, setSelectedToLocation] = useState();

    const [error, setError] = useState({
        fromLocationId: "",
        toLocationId: "",
        productId: "",
    });

    const [isEditMovement, setIsEditMovement] = useState(false);
    const handleEditMovement = () => {
        setIsEditMovement(!isEditMovement);
    };

    const [showModal, setShowModal] = useState(false);
    const handleCloseModal = () => setShowModal(false);
    const handleShowModal = () => setShowModal(true);
    const [showBatchModal, setShowBatchModal] = useState(false);
    const handleCloseBatchModal = () => setShowBatchModal(false);
    const handleShowBatchModal = () => setShowBatchModal(true);
    const [showMovementProductModal, setShowMovementProductModal] = useState(false);
    const handleCloseMovementProductModal = () => setShowMovementProductModal(false);
    const handleShowMovementProductModal = () => setShowMovementProductModal(true);


    const [search, setSearch] = useState("");
    const handleSearchChange = (value) => {
        const filterData = originMovementsProducts?.filter((item) =>
            item.productName.toLowerCase().includes(value.toLowerCase())
        );
        setSearch(value);
        setMovementsProductData(filterData);
    };

    //Validation

    const validateInput = (name, value) => {
        setError((prev) => {
            const stateObj = { ...prev, [name]: "" };
            switch (name) {
                case "fromLocationId":
                    if (value <= 0) {
                        stateObj[name] = "Please select at least one value";
                    }
                    break;

                case "toLocationId":
                    if (value <= 0) {
                        stateObj[name] = "Please select at least one value";
                    }
                    break;
                case "productId":
                    if (value <= 0) {
                        stateObj[name] = "Please select at least one value";
                    }
                    break;
                default:
                    break;
            }

            return stateObj;
        });
    };

    // Add New

    const handleAddMovementProduct = () => {
        setError([]);
        setProductValue({
            id: 0,
            movementId: movementId,
            productId: 0,
            productName: "string",
            quantity: 0,
            sent: 0,
            received: 0,
        });
        handleShowMovementProductModal();
    };

    //Locations

    const fetchLocationDropdown = async () => {
        try {
            let url = `/location/GetDropdownList?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url);
            const locations = await response.json();

            const products = locations?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setLocationDropdown(products);
        } catch (err) { }
    };

    const handleSaveChangeLocations = async (
        selectedFromLocation,
        selectedToLocation
    ) => {
        try {
            let url = `Movement/ChangeLocations?id=${singleMovement.id
                }&fromLocationId=${selectedFromLocation}&toLocationId=${selectedToLocation}&userId=${localStorage.getItem(
                    "userId"
                )}&userToken=${localStorage.getItem("userToken")}`;

            const response = await fetch(url, {
                method: "put",
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"],
                ],
            });

            if (response.status == 200) {
                showSweetAlert("saveSuccess", `Movement`);
                fetchSingleMovement(singleMovement.id);
                setIsEditMovement(false);
                if (props.fetchGetallpaged) {
                    props.fetchGetallpaged();
                }
            } else {
                showSweetAlert("warningOkOnly", response.statusText ?? "Error");
            }
        } catch (err) {
            console.log(err);
        }
    };

    const handleChangeLocation = (name, value) => {
        let item = {
            ...movementChangedValue,
            [name]: value,
        };
        setMovementChangedValue(item);
        validateInput(name, value);
    };
    const handleSaveMovement = () => {
        const newErrors = {};
        if (movementChangedValue.fromLocationId <= 0) {
            newErrors.fromLocationId = "Please select at least one value";
        }
        if (movementChangedValue.fromLocationId === movementChangedValue.toLocationId) {
            newErrors.toLocationId = "The to location cannot be the same as the from location";
        }
        if (movementChangedValue.toLocationId <= 0) {
            newErrors.toLocationId = "Please select at least one value";
        }
        setError(newErrors);

        if (Object.keys(newErrors).length > 0) {
            return;
        }

        showSweetAlert(
            "warning",
            `Are you sure you want to save the Movement?`,
            () => {
                saveMovement();
            }
        );
    };

    //Book in

    const handleMassBookIn = (movementId) => {
        showSweetAlert(
            "warning",
            `Are you sure you want to book in all items on this movement?`,
            () => {
                const massBookIn = async () => {
                    try {
                        let url = `Movement/MassBookIn?movementId=${movementId}&userId=${localStorage.getItem(
                            "userId"
                        )}&userToken=${localStorage.getItem("userToken")}`;

                        setIsLoading(true);
                        const body = [];
                        const barcodes = MovementsProductData.forEach((movement) => {
                            body.push(movement.barcode);
                        });

                        const response = await fetch(url, {
                            method: "post",
                            headers: [
                                ["Content-Type", "application/json"],
                                ["Content-Type", "text/plain"],
                            ],
                            data: JSON.stringify(body),
                        });

                        const data = await response.json();
                        if (response.status < 200 || response.status >= 300) {
                            showSweetAlert("errorCustomMessage", `${data}`);
                            return;
                        }

                        showSweetAlert("saveSuccess", `Movement`);
                        await fetchMovementProducts(movementId);
                        if (props.fetchGetallpaged) {
                            props.fetchGetallpaged();
                        }
                    } catch (err) {
                        showSweetAlert("serverError");
                        console.log("Error", err);
                    } finally {
                        setIsLoading(false);
                    }
                };
                massBookIn();
            }
        );
    };

    //Product

    const handleChangeProduct = (name, value) => {
        let updatedProductValue = {
            ...productValue,
            [name]: value,
        };
        setProductValue(updatedProductValue);

        const productWithId = productData.find((product) => product.id == updatedProductValue.productId);
        if (productWithId) {
            setQuantityInStock(productWithId.qty);
        }

        validateInput(name, value);
    };

    const handleSaveProduct = () => {
        if (productValue.quantity <= quantityInStock) {
            showSweetAlert(
                "warning",
                `Are you sure you want to Save the Movement Product?`,
                () => {
                    SaveProduct();
                }
            );
        } else {
            showSweetAlert(
                "warningOkOnly",
                `Selected location does not have enough of this item in stock.`
            );
        }
    };
    const SaveProduct = async () => {
        if (productValue.productId <= 0) {
            error.productId = "Please select atleast one value";
            return;
        }
        try {
            let url = `MovementProduct/Add?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url, {
                method: "post",
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"],
                ],
                body: JSON.stringify(productValue),
            });
            const body = await response.json();
            if (response.status == 200) {
                showSweetAlert("saveSuccess", `Product Value`);
                fetchMovementProducts(movementId);
                handleCloseMovementProductModal();
            } else {
                showSweetAlert("serverError");
            }
            fetchMovementProducts(movementId);
            handleCloseMovementProductModal();
        } catch (err) { }
    };

    //Product Line

    const handleRemoveMovementProductLine = (id) => {
        showSweetAlert(
            "warning",
            `Are you sure you want to remove this line from the movement?`,
            () => {
                confirmRemoveMovementProductLine(id);
            }
        );
    };

    const confirmRemoveMovementProductLine = async (id) => {
        try {
            const userId = localStorage.getItem("userId");
            const userToken = localStorage.getItem("userToken");
            const url = `MovementProduct/Delete?id=${id}&userId=${userId}&userToken=${userToken}`;
            const response = await fetch(url, {
                method: "delete",
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"],
                ],
            });
            if (response.status == 200) {
                showSweetAlert("success");
                fetchMovementProducts(movementId);
            } else {
                showSweetAlert("serverError");
            }
        } catch (error) {
            showSweetAlert("serverError");
        }
    };

    //get Data

    const fetchSingleMovement = async (id) => {
        try {
            let url = `/Movement/GetSingle?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
            const response = await fetch(url);
            const body = await response.json();
            setsingleMovement(body);
        } catch (err) { }
    };
    const fetchMovementProducts = async (id) => {
        try {
            let url = `/MovementProduct/getall?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}&movementId=${id}`;
            const response = await fetch(url);
            const body = await response.json();
            setMovementsProductData(body);
            setoriginMovementsProducts(body);
        } catch (err) { }
    };
    const fetchProductsDropdown = async () => {
        let locationId =
            singleMovement?.fromLocationId === undefined
                ? 0
                : singleMovement?.fromLocationId;
        try {
            let url = `/Product/GetProductDropdownListWithQuantity?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem(
                "userToken"
            )}&locationId=${locationId}`;
            const response = await fetch(url);
            const body = await response.json();
            const product = body?.map((item) => {
                return { id: item.id, value: item.name, qty: item.quantityInStock };
            });
            setProductdata(product);
        } catch (err) { }
    };

    //Movement

    const saveMovement = async () => {


        const fromLocationName = getLocationName(
            movementChangedValue.fromLocationId
        );
        const toLocationName = getLocationName(movementChangedValue.toLocationId);

        const data = {
            ...movementChangedValue,
            fromLocationName: fromLocationName,
            toLocationName: toLocationName,
        };

        try {
            let url = `Movement/Add?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}`;
            const response = await fetch(url, {
                method: "post",
                headers: [
                    ["Content-Type", "application/json"],
                    ["Content-Type", "text/plain"],
                ],
                body: JSON.stringify(data),
            });
            const body = await response.json();
            if (response.status == 200) {
                handleCloseBatchModal();
                showSweetAlert("saveSuccess", `Movement Product`);
            } else {
                showSweetAlert("errorCustomMessage", body);
            }
            if (props.fetchGetallpaged) {
                props.fetchGetallpaged();
            }
            handleCloseBatchModal();
        } catch (err) { }
    };

    useEffect(() => {
        fetchProductsDropdown()
    }, [singleMovement])

    const movementsModel = (movementId) => {
        setMovementId(movementId);
        fetchSingleMovement(movementId);
        fetchMovementProducts(movementId);
        handleShowModal();
        setIsLoading(false)
    };

    useEffect(() => {
        movementsModel(props.movementId)
    }, [props.movementId])

    useEffect(() => {
        fetchLocationDropdown()
    }, [])

    return (
        <>
            {showMovementProductModal == false && (
                <CustomModal show={showModal} handleClose={props.handleCloseMovementsModal} title="">
                    <MovementsModal
                        SingleMovement={singleMovement}
                        isLoading={isLoading}
                        MovementsProductData={MovementsProductData}
                        search={search}
                        handleSearchChange={handleSearchChange}
                        handleAddMovementProduct={handleAddMovementProduct}
                        handleGetItemCard={props.handleGetItemCard}
                        getLocationDropdown={getLocationDropdown}
                        // handleChangeLocation={handleChangeLocation}
                        handleEditMovement={handleEditMovement}
                        isEditMovement={isEditMovement}
                        handleSaveChangeLocations={handleSaveChangeLocations}
                        selectedFromLocation={selectedFromLocation}
                        setSelectedFromLocation={setSelectedFromLocation}
                        selectedToLocation={selectedToLocation}
                        setSelectedToLocation={setSelectedToLocation}
                        handleMassBookIn={handleMassBookIn}
                        userPermissions={user.permissions}
                        handleRemoveMovementProductLine={handleRemoveMovementProductLine}
                    />
                </CustomModal>
            )}
            <CustomModal
                show={showBatchModal}
                handleClose={handleCloseBatchModal}
                title="Add Movement"
                backdrop="static"
            >
                <AddEditMovementsModal
                    getLocationDropdown={getLocationDropdown}
                    handleChangeLocation={handleChangeLocation}
                    handleSaveMovement={handleSaveMovement}
                    error={error}
                    movementChangedValue={movementChangedValue}
                />
            </CustomModal>

            <CustomModal
                show={showMovementProductModal}
                handleClose={handleCloseMovementProductModal}
                title="Add Product"
                backdrop="static"
            >
                <AddEditMovementProduct
                    productData={productData}
                    handleChangeProduct={handleChangeProduct}
                    handleSaveProduct={handleSaveProduct}
                    productValue={productValue}
                    quantityInStock={quantityInStock}
                    error={error}
                />
            </CustomModal>
        </>
    )
}

export default MovementModalContainer