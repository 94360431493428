import React from "react";
import { useEffect, useState } from "react";
import CustomSearchBox from "../../components/Shared/CustomSearchBox";
import { useDispatch } from "react-redux";
import { showSweetAlert } from "components/Shared/CustomAlert";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
// react-bootstrap components
import {
    Form,
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container,
    Spinner,
} from "react-bootstrap";
import InfiniteScroll from "react-infinite-scroll-component";
import zIndex from "@mui/material/styles/zIndex";

function ProductTable(props) {
    ///Styling
    const SearchStyle = {
        marginTop: "-35px",
        marginLeft: "120px",
    };

    const handleGetLabelPDF = async (id, sku) => {
        try {
            const url = `Product/GetProductLabelsPDF?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}&id=${id}`;
            const response = await fetch(url);

            if (response.status == 200) {
                const base64String = await response.text();

                try {
                    const decodedData = Buffer.from(base64String, "base64").toString(
                        "binary"
                    );
                    const dataArray = new Uint8Array(
                        Array.from(decodedData, (char) => char.charCodeAt(0))
                    );
                    const blob = new Blob([dataArray], { type: "application/pdf" });

                    const blobUrl = window.URL.createObjectURL(blob);
                    const iframe = document.createElement("iframe");
                    iframe.style.display = "none";
                    iframe.src = blobUrl;
                    document.body.appendChild(iframe);

                    iframe.onload = function () {
                        try {
                            iframe.contentWindow.print();
                        } catch (printError) {
                            console.error("Error in printing:", printError);
                        }
                    };
                } catch (error) {
                    console.error("Error in processing the PDF:", error);
                }
            } else {
                const body = await response.text();
                showSweetAlert("warningCustomMessage", body);
            }
            //setIsLoading(false);
        } catch (e) {
            showSweetAlert("serverError");
        }
    };


    const renderTableData = () => {
        return props.data.map((log, index) => {
            const {
                brand,
                season,
                gender,
                productCategory,
                status,
                name,
                id,
                sku,
                colour,
                size,
                totalAvailableStock,
            } = log;
            return (
                <tr key={index}>
                    <td onClick={() => props.handleGetItemCard(id)}>
                        <a href="#id">{sku}</a>
                    </td>
                    <td>{name}</td>
                    <td>{brand}</td>
                    <td>{season}</td>
                    <td>{gender}</td>
                    <td>{productCategory}</td>
                    <td>{size}</td>
                    <td>{colour}</td>
                    <td>{totalAvailableStock}</td>
                    <td>{status}</td>
                    <td>
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            overlay={
                                <Tooltip id="tooltip-255158527">Download Labels PDF</Tooltip>
                            }
                        >
                            <Button
                                href="#"
                                onClick={(e) => handleGetLabelPDF(id, sku)}
                                variant="link"
                            >
                                <i className="fa fa-tag"></i>
                            </Button>
                        </OverlayTrigger>
                    </td>
                </tr>
            );
        });
    };


    return (
        <>
            {/* {alert} */}

            <Card className="strpied-tabled-with-hover">
                <Card.Header>
                    <Card.Title as="h4">Products</Card.Title>
                    <Col md="3" style={SearchStyle}>
                        <CustomSearchBox
                            placeholder="Search"
                            value={props.search}
                            handleChange={(e) => props.handleSearchChange(e)}
                            handleClear={() => props.handleSearchChange("")}
                        />
                    </Col>
                </Card.Header>
                <Card.Body className="table-responsive p-0">
                    {props.isLoading ? (
                        <div
                            style={{ height: 550 }}
                            className="d-flex justify-content-center align-items-center text-center"
                        >
                            <div>
                                <Spinner animation="border" />
                                <p>Loading...</p>
                            </div>
                        </div>
                    ) : (
                        <>
                            {props.data && props.data.length == 0 && (
                                <p
                                    style={{ height: 550 }}
                                    className="d-flex justify-content-center align-items-center text-center"
                                >
                                    No data found.
                                </p>
                            )}
                        </>
                    )}
                    {props.data && props.data.length > 0 && (
                        <Col md="12">
                            <InfiniteScroll
                                dataLength={props.data.length}
                                next={props.updateData}
                                hasMore={props.hasMore}
                                loader={
                                    props.data.length >= props.take ? (
                                        <h6>Loading more data...</h6>
                                    ) : (
                                        ""
                                    )
                                }
                                height={675}
                            >
                                <Table className="table-hover table-striped w-full">
                                    <thead style={{ zIndex: "1000" }}>
                                        <tr>
                                            <th>SKU</th>
                                            <th>Product Name</th>
                                            <th>Brand</th>
                                            <th>Season</th>
                                            <th>Gender</th>
                                            <th>Category</th>
                                            <th>Size</th>
                                            <th>Colour</th>
                                            <th>Available Stock</th>
                                            <th>Status</th>
                                            <th></th>
                                        </tr>
                                    </thead>
                                    <tbody>{renderTableData()}</tbody>
                                </Table>
                            </InfiniteScroll>
                        </Col>
                    )}
                </Card.Body>
            </Card>
        </>
    );
}
export default ProductTable;
