import React, { useState, useEffect, useMemo, useRef } from "react";
import { Form } from "react-bootstrap";
import CustomModal from "components/Shared/CustomModal";
import { Card, Container, Row, Col, Button } from "react-bootstrap";
import { useSelector } from "react-redux";
import { showSweetAlert } from "components/Shared/CustomAlert";
import CustomSelect from "../../components/Shared/CustomSelect";
import CustomAsyncSelect from "../../components/Shared/CustomAsyncSelect";
import ProductTable from "components/Products/ProductsTable";
import InventoryModal from "../../components/ItemCard/InventoryModal";
import WebOrdersModal from "components/ItemCard/WebOrdersModal";
import ItemCard from "../../components/ItemCard/ItemCard";
import Loading from "components/Shared/Loading";
import animationData from "../../assets/animations/orange-square.json";

import { PRODUCTS_VIEW } from "constants/Permissions.js";
import { getPermission } from "utils/Permissions.js";
import ModalContainer from "../../components/Shared/ModalContainer";
import MovementModalContainer from "../../components/Movements/MovementModalContainer";

function Products() {
    //Styling
    const divStyle = {
        marginTop: "24px",
    };
    const archived = {
        marginTop: "-21px",
        marginLeft: "20px",
    };
    const user = useSelector((state) => state.user);

    // ### STATE ###
    const [isLoading, setIsLoading] = useState(true);
    const [IsChecked, setCheck] = useState(false);
    const [page, setPage] = useState(0);
    const [skip, setSkip] = useState(0);
    const [take, setTake] = useState(20);
    const [hasMore, setHasMore] = useState(true);
    const [types, setTypes] = useState([]);
    const [type, setType] = useState(0);
    const [brands, setBrands] = useState([]);
    const [locations, setLocations] = useState([]);
    const [genders, setGenders] = useState([]);
    const [seasons, setSeasons] = useState([]);
    const [categories, setCategories] = useState([]);
    const [statuses, setStatuses] = useState([]);

    //Filters
    const [location, setLocation] = useState(0);
    const [brand, setBrand] = useState(0);
    const [search, setSearch] = useState("");
    const [status, setStatus] = useState(0);
    const [gender, setGender] = useState(0);
    const [category, setCategory] = useState(0);
    const [season, setSeason] = useState(0);
    const [includeArchived, setIncludeArchived] = useState(false);

    const updateIncludeArchived = (event) => {
        setIncludeArchived(event.target.checked);
        handlePageChange(0);
        setLocation(0);
        setBrand(0);
        setSearch(0);
        setStatus(0);
        setGender(0);
        setCategory(0);
        setSeason(0);
        handleProductSearchChange("");
        updateData();
    };

    const [orderBy, setOrderBy] = useState("Sku");
    const [descending, setDescending] = useState(false);
    const [salesData, setSalesData] = useState([]);
    const [auditLogs, setAuditLogs] = useState([]);
    const [webDetails, setWebDetails] = useState([]);

    const [data, setData] = useState([]);

    const [productId, setProductId] = useState("");
    const [productName, setProductName] = useState("");
    const [inventory, setInventory] = useState([]);

    //hooks
    //const handleCloseModal = () => setShowItemCardModal(false);
    //const handleShowModal = () => setShowItemCardModal(true);

    const [selectedProductId, setSelectedProductId] = useState(0);

    const [sales, setSales] = useState([]);
    const [audit, setAudit] = useState([]);
    const [details, setDetails] = useState({});

    const [isValidation, setIsValidation] = useState(false);
    const [oldQuantity, setoldQuantity] = useState("");

    const clearFilters = () => {
        setLocation(0);
        setBrand(0);
        setGender(0);
        setCategory(0);
        setSeason(0);
        setStatus(0);
        handleProductSearchChange("");
    };

    const handleProductSearchChange = (value) => {
        setSearch(value);
    };

    const clearProductInventoryValidation = () => {
        setIsValidation(false);
    };

    const handlePermission = () => {
        showSweetAlert("unauthorized");
    };

    const handleError = () => {
        showSweetAlert("serverError");
    };

    // ### DATA FETCHES ##
    const fetchDropdowns = async () => {
        try {
            const filters = {
                locations: location !== 0 ? [location] : null,
                brands: brand !== 0 ? [brand] : null,
                seasons: season !== 0 ? [season] : null,
                genders: gender !== 0 ? [gender] : null,
                categories: category !== 0 ? [category] : null,
                statuses: status !== 0 ? [status] : null,
            };

            //Get data dropdowns
            let filterDropdownUrl = `/Product/GetProductDropdowns?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem(
                "userToken"
            )}&includeArchived=${includeArchived}`;
            const filterDropdownResponse = await fetch(filterDropdownUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(filters), // Convert the filters to JSON
            });

            const filterDropdownBody = await filterDropdownResponse.json();

            const locationData = filterDropdownBody.locations?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setLocations(locationData);

            const brandData = filterDropdownBody.brands?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setBrands(brandData);

            const seasonData = filterDropdownBody.seasons?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setSeasons(seasonData);

            const genderData = filterDropdownBody.genders?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setGenders(genderData);

            const categoryData = filterDropdownBody.categories?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setCategories(categoryData);

            const statusData = filterDropdownBody.statuses?.map((item) => {
                return { id: item.id, value: item.name };
            });
            setStatuses(statusData);
        } catch (err) { }
    };

    const updateData = async () => {
        try {
            handlePageChange(page + 1);
            let url = `/Product/GetAllPaged?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}&skip=${pageRef.current * take + skip
                }&take=${take}&locationId=${location}&brandId=${brand}&seasonId=${season}&genderId=${gender}&categoryId=${category}&statusId=${status}&search=${search}&orderBy=${orderBy}&descending=${descending}&includeArchived=${includeArchived}`;
            const response = await fetch(url);
            const body = await response.json();
            setTimeout(() => {
                setData(data.concat(body.data));
                if (body.data && body.data.length == 0) {
                    setHasMore(false);
                }
            }, 500);
        } catch (err) { }
    };

    //const handleGetItemCardCommittedList = async (id, location) => {
    //    if (!id) return;
    //    try {
    //        const url = `/ItemCard/GetCommittedList?userId=${localStorage.getItem(
    //            "userId"
    //        )}&userToken=${localStorage.getItem(
    //            "userToken"
    //        )}&productId=${id}&locationId=${location}`;
    //        const response = await fetch(url);
    //        const body = await response.json();
    //        return body;
    //    } catch (err) {
    //        showSweetAlert("serverError");
    //    }
    //};

    //const handleGetItemCardInTransitList = async (id, location) => {
    //    if (!id) return;
    //    try {
    //        const url = `/ItemCard/GetInTransitList?userId=${localStorage.getItem(
    //            "userId"
    //        )}&userToken=${localStorage.getItem(
    //            "userToken"
    //        )}&productId=${id}&locationId=${location}`;
    //        const response = await fetch(url);
    //        const body = await response.json();
    //        return body;
    //    } catch (err) {
    //        showSweetAlert("serverError");
    //    }
    //};

    //const handleGetItemCardSoldList = async (id, location) => {
    //    if (!id) return;
    //    try {
    //        const url = `/ItemCard/GetSoldList?userId=${localStorage.getItem(
    //            "userId"
    //        )}&userToken=${localStorage.getItem(
    //            "userToken"
    //        )}&productId=${id}&locationId=${location}`;

    //        const response = await fetch(url);
    //        const body = await response.json();
    //        return body;
    //    } catch (err) {
    //        showSweetAlert("serverError");
    //    }
    //};

    //const [itemCardWebOrderInfoType, setItemCardWebOrderInfoType] = useState();
    //const [itemCardWebOrderInfo, setItemCardWebOrderInfo] = useState([]);
    //const handleGetItemCardWebOrderInfo = async (type, productId, locationId) => {
    //    switch (type) {
    //        case "onHand":
    //            // do nothing
    //            break;
    //        case "Committed":
    //            setItemCardWebOrderInfo(
    //                await handleGetItemCardCommittedList(productId, locationId)
    //            );
    //            setItemCardWebOrderInfoType("Committed");
    //            break;
    //        case "InTransit":
    //            setItemCardWebOrderInfo(
    //                await handleGetItemCardInTransitList(productId, locationId)
    //            );
    //            setItemCardWebOrderInfoType("In Transit");
    //            break;
    //        case "Sold":
    //            setItemCardWebOrderInfo(
    //                await handleGetItemCardSoldList(productId, locationId)
    //            );
    //            setItemCardWebOrderInfoType("Sold");
    //            break;
    //        default:
    //            break;
    //    }
    //};

    // ### Item Cards
    //const handleGetItemCards = async () => {
    //    handleShowModal();
    //};

    //ModalContainer
    const handleGetItemCard = (id) => {
        setSelectedProductId({id: id, first: "itemCard"});
        //setShowItemCardModal(true);
    };

    const handleItemCardCloseModal = () => {
        //setShowItemCardModal(false);
        setSelectedProductId(0);
    };

    //const handleShowInventoryModal = (item) => {
    //    setSelectedInventory(item);
    //    setShowInventoryViewModal(true);
    //    setShowItemCardModal(false);
    //};

    //const handleHideInventoryModal = () => {
    //    setShowInventoryViewModal(false);
    //    setShowItemCardModal(true);
    //};

    //const [showWebOrdersModal, setShowWebOrdersModal] = useState(false);
    //const [isNavBack, setIsNavBack] = useState(null);
    //const handleShowWebOrdersModal = (item) => {
    //    setSelectedInventory(item);
    //    setIsNavBack(false);
    //    setShowItemCardModal(false);
    //    setShowWebOrdersModal(true);
    //};

    //const handleHideWebOrdersModal = () => {
    //    setShowWebOrdersModal(false);
    //    setShowItemCardModal(true);
    //    setIsNavBack(true);
    //    setItemCardWebOrderInfo([]);
    //    setItemCardWebOrderInfoType("");
    //};

    const pageRef = useRef(page);

    const handlePageChange = (newPage) => {
        pageRef.current = newPage;
        setPage(newPage);
    };

    // ### HOOKS ###
    useMemo(async () => {
        handlePageChange(0);
        try {
            fetchDropdowns();
            setData([]);
            setIsLoading(true);
            let url = `/Product/GetAllPaged?userId=${localStorage.getItem(
                "userId"
            )}&userToken=${localStorage.getItem("userToken")}&skip=${page * take + skip
                }&take=${take}&locationId=${location}&brandId=${brand}&seasonId=${season}&genderId=${gender}&categoryId=${category}&statusId=${status}&search=${search}&orderBy=${orderBy}&descending=${descending}&includeArchived=${includeArchived}`;
            const response = await fetch(url);
            const body = await response.json();
            setData(body.data);
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
        }
    }, [location, brand, search, season, gender, category, status]);

    //useEffect(() => {
    //    fetchDropdowns();
    //}, []);

    const [movementId, setMovementId] = useState(0);
    //temp
    const handleCloseMovementsModal = () => {
        setMovementId(0)
    }

    const handleSelectMovement = (movement) => {
        setMovementId(movement.id)
        setSelectedProductId(0)
    }


    return (
        <>
            {alert}
            <CustomModal
                show={selectedProductId !== 0}
                handleClose={handleItemCardCloseModal}
                title=""
                backdrop="static"
            >
                <ModalContainer
                    selectedProductId={selectedProductId}
                    handleItemCardCloseModal={handleItemCardCloseModal}
                    handleSelectMovement={handleSelectMovement}
                />
            </CustomModal>

            <CustomModal
                show={movementId !== 0 && selectedProductId === 0}
                handleClose={handleCloseMovementsModal}
                title=""
                backdrop="static"
            >
                <MovementModalContainer
                    movementId={movementId}
                    handleCloseMovementsModal={handleCloseMovementsModal}
                    handleGetItemCard={handleGetItemCard}
                />
            </CustomModal>

            

            {getPermission(user.permissions, PRODUCTS_VIEW) && (
                <Container fluid>
                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <Card>
                                    <Card.Body>
                                        <Row>
                                            <Col md="">
                                                {/*<CustomAsyncSelect data={locations}*/}
                                                {/*title="Location" />*/}

                                                <CustomSelect
                                                    title="Location"
                                                    placeholder="Location"
                                                    id={location}
                                                    data={locations}
                                                    handleChange={(e) => setLocation(e.value)}
                                                    withAll={true}
                                                />
                                            </Col>
                                            <Col md="">
                                                <CustomSelect
                                                    title="Brand"
                                                    placeholder="Brand"
                                                    id={brand}
                                                    data={brands}
                                                    handleChange={(e) => setBrand(e.value)}
                                                    withAll={true}
                                                />
                                            </Col>
                                            <Col md="">
                                                <CustomSelect
                                                    title="Season"
                                                    placeholder="Season"
                                                    id={season}
                                                    data={seasons}
                                                    handleChange={(e) => setSeason(e.value)}
                                                    withAll={true}
                                                />
                                            </Col>
                                            <Col md="">
                                                <CustomSelect
                                                    title="Gender"
                                                    placeholder="Gender"
                                                    id={gender}
                                                    data={genders}
                                                    handleChange={(e) => setGender(e.value)}
                                                    withAll={true}
                                                />
                                            </Col>
                                            <Col md="">
                                                <CustomSelect
                                                    title="Category"
                                                    placeholder="Category"
                                                    id={category}
                                                    data={categories}
                                                    handleChange={(e) => setCategory(e.value)}
                                                    withAll={true}
                                                />
                                            </Col>
                                            <Col md="">
                                                <CustomSelect
                                                    title="Status"
                                                    placeholder="Status"
                                                    id={status}
                                                    data={statuses}
                                                    handleChange={(e) => setStatus(e.value)}
                                                    withAll={true}
                                                />
                                            </Col>

                                            <Col md="1" className="pt-3">
                                                <Button onClick={() => clearFilters()}>Clear</Button>
                                            </Col>

                                            <Col md="" style={divStyle}>
                                                <Form.Check
                                                    // checked={IsChecked}
                                                    onChange={updateIncludeArchived}
                                                    value={includeArchived}
                                                    type="switch"
                                                    id="custom-switch"
                                                />
                                                <h5 style={archived}>Include archived</h5>
                                            </Col>
                                        </Row>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>

                    <Container fluid>
                        <Row>
                            <Col md="12">
                                <ProductTable
                                    isLoading={isLoading}
                                    data={data}
                                    updateData={updateData}
                                    search={search}
                                    handleSearchChange={handleProductSearchChange}
                                    take={take}
                                    hasMore={hasMore}
                                    handleGetItemCard={handleGetItemCard}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Container>
            )}
            {!getPermission(user.permissions, PRODUCTS_VIEW) && (
                <Container className="mt-4 ms-4">
                    You are not authorized to view this page
                </Container>
            )}
        </>
    );
}
export default Products;
