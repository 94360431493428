import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
// react-bootstrap components
import { Button, Card, Form, Row, Col } from "react-bootstrap";

import CustomSelect from "../../components/Shared/CustomSelect";
import { showSweetAlert } from "components/Shared/CustomAlert";

import {
    ADMIN_USERMANAGEMENT_VIEW,
    ADMIN_USERMANAGEMENT_EDIT,
    ADMIN_USERMANAGEMENT_DELETE,
} from "constants/Permissions.js";
import { getPermission } from "utils/Permissions.js";

function RolePage(props) {
    const user = useSelector((state) => state.user);

    const [selectedRoles, setSelectedRoles] = useState([])
    const [unselectedRoles, setUnselectedRoles] = useState([])
    const [permissions, setPermissions] = useState([])

    const formatRoles = () => {
        const selectedIds = new Set(props.permissions.map(permission => permission.id))
        const selected = props.basePermissions.filter(permission => selectedIds.has(permission.id))
        const unselected = props.basePermissions.filter(permission => !selectedIds.has(permission.id))

        setSelectedRoles(selected)
        setUnselectedRoles(unselected)
    };

    const formatPermissions = () => {
        const groupedPermissions = props.basePermissions.reduce((acc, permission) => {
            const [category] = permission.name
                .split('.') // Extract prefix before the dot
                .map((part) => part.replace(/([a-z])([A-Z])/g, '$1 $2')); // Add space in camel case

            if (!acc[category]) {
                acc[category] = [];
            }
            acc[category].push({
                id: permission.id,
                name: permission.name.split(".")[1],
            });
            return acc;
        }, {});

        setPermissions(groupedPermissions);
    };


    useEffect(() => {
        formatPermissions()
    }, [props.basePermissions])



    useEffect(() => {
        if (permissions && props.permissions && props.permissions.length > 0)
            formatRoles()
    }, [permissions, props.permissions, props.roleData])


    // ### HOOKS ###
    useEffect(() => {
        // set the width of the modal
        document.documentElement.style.setProperty("--modal-width", "1200px");
    }, []);

    const handleToggleRole = (checked, role) => {
        const findRole = props.basePermissions.find((x) => x.id === role)

        if (checked) {
            setSelectedRoles((prevSelected) => [...prevSelected, findRole])
            setUnselectedRoles((prevUnselected) =>
                prevUnselected.filter((x) => x.id !== role)
            );
        } else {
            setSelectedRoles((prevSelected) =>
                prevSelected.filter((x) => x.id !== role)
            );
            setUnselectedRoles((prevUnselected) => [...prevUnselected, findRole])
        }
    };

    return (
        <>
            {alert}
            <Row>
                <Col md="12">
                    <Form action="" className="form" method="">
                        <Card>
                            <Card.Body style={{ minHeight: "353px" }}>
                                {getPermission(user.permissions, ADMIN_USERMANAGEMENT_EDIT) && (
                                    <Row>
                                        <Col className="pr-1" md="12">
                                            <Button
                                                className="btn-link btn-xs btn-action float-end"
                                                variant="success"
                                                onClick={() => props.handleEdit()}
                                            >
                                                {props.userId !== 0 && <i className="fas fa-edit"></i>}
                                            </Button>
                                        </Col>
                                    </Row>
                                )}
                                <Row>
                                    <Col className="pr-1" md="4">
                                        <Form.Group>
                                            <label>Role</label>
                                            {props.isEdit ? (
                                                <Form.Control
                                                    placeholder="Role"
                                                    name="role"
                                                    type="text"
                                                    value={props.roleData.name}
                                                    onChange={(e) =>
                                                        props.handleChange("name", e.target.value)
                                                    }
                                                    onBlur={(e) =>
                                                        props.validateInput("name", e.target.value)
                                                    }
                                                ></Form.Control>
                                            ) : (
                                                <Form.Control
                                                    disabled
                                                    placeholder="Role"
                                                    name="role"
                                                    type="text"
                                                    value={props.roleData.name}
                                                    onChange={(e) =>
                                                        props.handleChange("name", e.target.value)
                                                    }
                                                    onBlur={(e) =>
                                                        props.validateInput("name", e.target.value)
                                                    }
                                                ></Form.Control>
                                            )}
                                        </Form.Group>
                                        {props.error.name && (
                                            <label style={{ color: "red" }} className="err">
                                                {props.error.name}
                                            </label>
                                        )}

                                    </Col>
                                </Row>
                                <Row>
                                    {permissions &&
                                        Object.entries(permissions).map(([category, items]) => (
                                            <Col key={category} className="permission-group" md="3">
                                                <h3 className="permission-category">{category}</h3>
                                                <Row className="permission-items">
                                                    {items.map((item) => (
                                                        <Col key={item.id} md="12" className="archivedCol m-1">
                                                            <Form.Check
                                                                onChange={(e) => handleToggleRole(e.target.checked, item.id)}
                                                                type="switch"
                                                                id={`custom-switch-${item.id}`}
                                                                checked={selectedRoles.some((selected) => selected.id === item.id)}
                                                            />
                                                            <h5 className="archived" style={{ width: "119px" }}>
                                                                {item.name}
                                                            </h5>
                                                        </Col>
                                                    ))}
                                                </Row>
                                            </Col>
                                        ))}
                                </Row>



                                <Button
                                    className="btn-fill float-end"
                                    variant="primary"
                                    onClick={() => props.handleShowSave(selectedRoles)}
                                >
                                    Save
                                </Button>

                                {props.handleShowCancel !== undefined && (
                                    <Button
                                        className="btn-fill float-end"
                                        variant="info"
                                        onClick={handleShowCancel}
                                    >
                                        Cancel
                                    </Button>
                                )}
                                <div className="clearfix"></div>
                            </Card.Body>
                        </Card>
                    </Form>
                </Col>

            </Row>
        </>
    );
}

export default RolePage;
