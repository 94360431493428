import React from "react";
import { useEffect, useState } from 'react';
import { showSweetAlert } from 'components/Shared/CustomAlert';
// react-bootstrap components
import {
    Button,
    Card,
    Col,
    OverlayTrigger,
    Row,
    Table,
    Tooltip,
    Container
} from "react-bootstrap";

function RoleTable(props) {
    const [data, setData] = useState([]);
    

    const renderTableData = () => {
        return data.map((role) => {
            const { id, name } = role
            return (
                <tr key={`Role${id}`} className={props.roleId === id ? "table-selected-row" : ""}>
                    <td onClick={() => props.handleShow(id)}>{name}</td>
                    <td className="d-flex justify-content-end">
                        <OverlayTrigger
                            href="#pablo"
                            onClick={(e) => props.handleDelete(id)}
                            overlay={
                                <Tooltip id="tooltip-255158527">Delete</Tooltip>
                            }
                        >
                            <Button
                                className="btn-link btn-xs"
                                href="#pablo"
                                onClick={(e) => props.handleDelete(id)}
                                variant="danger"
                            >
                                <i className="fas fa-times"></i>
                            </Button>
                        </OverlayTrigger>
                    </td>
                </tr>
            )
        })
    }

    const deleteAlert = (id) => {
        showSweetAlert('deleteWarning', `role`, () => { successDelete(id) });
    };
    const successDelete = async (id) => {
        try {
            await fetch(`/role/delete?roleId=${id}`, { method: 'delete' });
            showSweetAlert('deleteSuccess', `Role`);
        } catch (err) {
            showSweetAlert('serverError');
        }
    };
    const hideAlert = () => {
        
        props.refresh();
    };

    useEffect(() => {
        setData(props.rolesData);
    }, [props.rolesData]);

    return (
        <>
            {alert}
            <Card className="strpied-tabled-with-hover">
                <Card.Header>
                    <Card.Title as="h4" className="d-flex justify-content-between align-items-center">
                        Roles
                        <Button
                            className="btn-fill"
                            variant="primary"
                            onClick={() => props.handleAdd()}>
                            Add
                        </Button>
                    </Card.Title>
                    
                </Card.Header>
                <Card.Body className="table-responsive p-0">
                    <Col md="12" className="shift-table-container">
                        <Table className="table-hover table-striped w-full">
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th className="text-right"></th> {/*Actions*/}
                                </tr>
                            </thead>
                            <tbody>
                                {renderTableData()}
                            </tbody>
                        </Table>
                    </Col>
                </Card.Body>
            </Card>
        </>
    );
}

export default RoleTable;
